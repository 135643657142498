$bg-color: linear-gradient(
  to bottom,
  #ab3be3,
  #854fe2,
  #5f5adb,
  #3860d0,
  #0062c1
);
$primary: #f2d6ce;
$white: #ffffff;
$grayLight: #f2f2f2;
$gray: #d9d9d9;
@mixin size__320 {
  @media only screen and (min-width: 320px) {
    @content;
  }
}
@mixin size__640 {
  @media only screen and (min-width: 640px) {
    @content;
  }
}
@mixin size__768 {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin size__1024 {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
@mixin size__1280 {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}
