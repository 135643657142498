@import "variables.module";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html,
body {
  font-family: Prompt;
  margin: 0;
}
br {
  margin: 2em;
  display: block;
  font-size: 24%;
  content: "";
}
#__next {
  font-family: Prompt;
}
.logo-img {
  width: 200px !important;
  height: 31px !important;
}
.h2 {
  margin-top: 0 !important;
  margin-bottom: 0.5em !important;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2666666666666666 !important;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.h3 {
  margin-top: 0 !important;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.h4 {
  margin-top: 0 !important;
  margin-bottom: 0.5em !important;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4 !important;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.h5 {
  margin-top: 0 !important;
  margin-bottom: 0.5em !important;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5 !important;
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.bg-luxury {
  background-color: $primary !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 7px !important;
}
.mt-2 {
  margin-top: 14px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 7px !important;
}
.mb-2 {
  margin-bottom: 14px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100vh; /* หรือ min-height: 100vh; */
}
.ant-layout-header {
  background: #ffffff !important;
}
.no-link {
  cursor: default !important;
  color: #000000 !important;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}
.text-center {
  text-align: center;
}
.text-gray {
  color: $gray;
}
.m-container {
  margin-block: 24px;
}
.mt-container {
  margin-top: 24px;
}
.mb-container {
  margin-bottom: 24px;
}
.social-swiper-container {
  padding-left: 24px;
  padding-right: 24px;
  min-width: 100%;
  .social-title {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
}
.section-product {
  height: 1416px;
}
.component-product-swipper {
  height: 472px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.swiper-wrapper {
  height: 100% !important;
}
.swiper-container {
  // padding-left: 24px;
  // padding-right: 24px;
  min-width: 100%;
  height: 410px;

  .swiper-slide {
    width: auto !important;
    height: 100% !important;
  }
  .card-product-container {
    width: auto;
    padding: 7px;
    display: flex;
  }
  .card-product {
    width: 250px;
    background-color: white;
    border: 0 !important;
    .ant-card-cover,
    .card-product-img {
      width: 100%;
      // padding-top: 100%;
      position: relative;
      overflow: hidden;
    }
    .card-product-detail {
      padding: 15px !important;
      color: rgba(0, 0, 0, 0.88) !important;
      font-size: 14px;
      .title3line {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    }
    .ant-card-body {
      padding: 15px !important;
      overflow: hidden;
      .ant-card-meta {
        .ant-card-meta-title {
          margin-bottom: 0;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 400;
          height: 3.2em;
          font-size: 13px;
        }
        .ant-card-meta-description {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.card-product {
  width: 250px;
  // height: 386.8px;
  overflow: hidden;
  background-color: white;
  border: 0;
  .ant-card-cover {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
  }
  .ant-card-body {
    padding: 15px !important;
    overflow: hidden;
    .ant-card-meta {
      .ant-card-meta-title {
        margin-bottom: 0;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 400;
        height: 3.2em;
        font-size: 13px;
      }
      .ant-card-meta-description {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.w-100 {
  width: 100% !important;
}
.p {
  padding: 7px !important;
}
.p-2 {
  padding: 14px !important;
}
.p-3 {
  padding: 24px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.border-0 {
  border: 0 !important;
}
.bg {
  // background-image: $bg-color;
  width: 100% !important;
  max-width: 100% !important;
  height: calc(100vh - 56px);
}
.bg-white {
  background-color: $white !important;
}
.bg-gray {
  background-color: $grayLight !important;
}
.bg-grayDark {
  background-color: $gray !important;
}
.ant-alert-info {
  background-color: $primary !important;
  border: 0 !important;
  margin-block: 14px !important;
}

.layout-main-content {
  background-color: white !important;
  margin-bottom: -20px !important;
}
.header_container {
  background-color: transparent !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}
.header_logo {
  display: flex;
  align-items: center;
  width: 45% !important;
}
.header_menu_ui {
  border: 0;
  justify-content: flex-end;
  @include size__640 {
    min-width: 100px;
  }
  @include size__768 {
    min-width: 300px;
  }
  @include size__1024 {
    min-width: 500px;
  }
}
.style_header_menu {
  width: 55% !important;
  justify-content: right;
  display: flex;
}
.menu_cate {
  justify-content: center;
  background-color: #f6f6f6 !important;
  border: 0 !important;
}

.menu-icon {
  font-size: 18px !important;
}
.menu-currency {
  width: 76px !important;
}
.d-flex {
  display: flex;
}
.d-flex-center {
  display: flex;
  align-items: center;
}

.btn-scan {
  background-color: #ffffff !important;
  color: #000000;
  border-radius: 20px;
  min-width: 150px;
}
.btn-signin {
  min-width: 81px;
  text-align: center;
}
.btn-currency {
  min-width: 80px;
  text-align: center;
}
// .swal2-title,
// .swal2-styled {
//   font-family: SukhumvitSet;
// }
.link {
  color: $primary !important;
  cursor: pointer !important;
  display: flex;
}
.link-black {
  cursor: pointer;
  text-decoration: underline;
}

.block11 {
  background-color: #d9d9d9 !important;
  width: 100%;
  aspect-ratio: 1 / 1;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.font-primary {
  color: $primary;
}
.font-20 {
  font-size: 20px;
}
.font-18 {
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}

@mixin layout-shadow {
  // transition: 0.3s;
  // box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.08);
}

.main-content {
  min-height: 480px;
  margin-top: 65px;
  @include size__320 {
    margin-top: 65px;
  }
  @include size__640 {
    margin-top: 65px;
  }
  @include size__768 {
    margin-top: 65px;
  }
  @include size__1024 {
    margin-top: 133px;
  }
  @include size__1280 {
    margin-top: 133px;
  }
}

.logo {
  // height: 1em;
  // margin-left: 0.5rem;
  // float: left;
  cursor: pointer;
}
.search-box {
  // width: auto !important;
  flex: 1;
  margin-left: 15px;
}
.v-center {
  text-align: center;
  align-self: center;
}

.swiper {
  position: unset !important;
}

.custom-checkbox {
  .ant-checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: "#000000" !important;
      border-color: "#000000" !important;
    }
  }
  margin-bottom: 24px;
}

.modal-menu {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  transform-origin: 85px 43px;
  overflow: hidden;
  max-width: 100% !important;
  .ant-modal-content {
    height: 100%;
    border-radius: 0 !important;
  }
}
.ant-modal {
  width: 100%;
  transform-origin: 85px 43px;
  overflow: hidden;
  height: -webkit-fill-available;
}
.ant-modal-center {
  height: auto;
}

.modal-profile {
  margin-top: 15px !important;
  // height: auto !important;
  .modal-profile-container {
    min-height: 480px;
    max-height: 640px;
    overflow-x: scroll;
  }
}
.modal-currency {
  margin-top: 15px !important;
  // height: auto !important;
  .modal-profile-container {
    min-height: 480px;
    max-height: 640px;
    overflow-x: scroll;
  }
}
.modal-brand {
  top: 15px !important;
  // height: auto !important;
  .search {
    margin-inline: 5px;
    margin-bottom: 14px;
    border: 0 !important;
    .ant-input-affix-wrapper {
      background-color: #e6e6e6 !important;
      input {
        background-color: #e6e6e6 !important;
      }
    }
  }
  .modal-brand-container {
    max-height: 480px;
    overflow-x: scroll;
  }
}
.voucher-inactive {
  background-color: #eeeeee;
  padding: 5px 10px 0 !important;
  border-radius: 5px;
  margin-bottom: 5px;
}
.voucher-active {
  padding: 5px 10px 0 !important;
  margin-bottom: 5px;
}

.img-100 {
  width: 100%;
}

.btn-luxury {
  color: #000000;
  border: 1px solid $primary;
  border-radius: 0;

  font-size: 14px;
  height: 32px;
  padding: 4px 15px;

  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent !important;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: rgba(0, 0, 0, 0.88);

  // font-weight: 700;
  &:hover {
    color: #000000 !important;
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
}

.btn-luxury-pink {
  color: #000000 !important;
  background-color: $primary !important;
  border: 1px solid $primary !important;
  border-radius: 0;
  font-weight: 700;
  &:hover {
    color: #000000 !important;
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
}

.ant-card-actions {
  li {
    text-align: right !important;
    padding: 0 15px;
  }
}

.footer-totop {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -48px;
}

.image-container {
  width: 100%;

  > div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

.image-container-logo {
  width: 100%;
  max-width: 180px;
  margin-bottom: 15px;

  span {
    span {
      padding-top: 0 !important;
    }
  }

  > div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

video {
  width: 100% !important;
  height: auto !important;
  min-height: 100% !important;
}

.btn-confirm {
  background-color: #ffffff !important;
  padding: 5px !important;
  margin: 7px !important;
  margin-top: 10px !important;
  &:hover {
    background-color: #ffffff !important;
  }
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table > :not(caption) > * > * {
  padding: 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  // box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.pdpa {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 15px;
}

.loader {
  width: 100%;
  padding: 24px;
  text-align: center;
}

.main-filter {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 0;
    }
    .ant-collapse-content {
      padding: 0;
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.inside-filter-brand {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 0;
    }
    .ant-collapse-content {
      max-height: 300px;
      overflow-y: scroll;
      padding: 0;
    }
  }
}
.inside-filter {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 0;
    }
    .ant-collapse-content {
      max-height: 300px;
      overflow-y: scroll;
      padding: 0;
    }
  }

  .select-color {
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper {
      margin-right: 0;
      border: 1px solid #dedede;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;
      border-radius: 7px;
      margin-bottom: 7px;
      .ant-radio {
        display: none;
      }
      .select-color-container {
        display: flex;
        align-items: center;
        .select-color-radio {
          margin-right: 10px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          .color {
            width: 35px;
            height: 33px;
            border-radius: 50%;
            background-color: red;
          }
          .anticon {
            position: absolute;
            color: white;
            display: none;
          }
        }
      }
    }
    .ant-radio-wrapper-checked {
      .select-color-container {
        .select-color-radio {
          border: 1px solid #000000;
          .anticon {
            display: inline-block;
          }
        }
      }
    }
  }
}

.checkbox-brand {
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin-inline-start: 0;
    margin-bottom: 7px;
  }
}

.ant-slider .ant-slider-track {
  background-color: #000000;
}

.ant-slider .ant-slider-handle::after {
  // box-shadow: 0 0 0 2px #000000;
}

.size-guide {
  .ant-modal-content {
    .ant-modal-body {
      margin-block: 24px;
      max-height: 450px;
      overflow-y: scroll;
    }
  }
}

.label-require {
  .ant-row {
    .ant-col {
      .ant-form-item-required {
        &::before {
          display: none !important;
        }
      }
    }
  }
}

.container {
  h4 {
    margin-top: 1.3em;
  }

  p,
  li {
    line-height: 1.3em;
    margin-bottom: 0.5em;
  }
}
.ant-menu-horizontal {
  border-bottom: 0 !important;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #000000 !important;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after {
  border-bottom-color: #000000 !important;
}
.ant-menu-light .ant-menu-item-selected a {
  color: #000000 !important;
}
.ant-menu-item-selected {
  background-color: transparent !important; /* ลบสีพื้นหลัง */
  color: #000000 !important;
}
.ant-menu-item {
  padding-inline: 10px !important;
  font-weight: 500;
}
.ant-btn-primary {
  background-color: #000000 !important;
  &:disabled {
    opacity: 0.5;
    color: #ffffff !important;
  }
}
.form-control {
  width: 100% !important;
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  height: 40px;
}
.ant-radio-wrapper {
  // width: 100%;
  .ant-radio {
    width: 16px;
  }
  span:not(.ant-radio) {
    width: 100%;
  }
}
.radio-register {
  // width: 100%;
  .ant-radio {
    min-width: 16px;
    min-height: 16px;
  }
  span:not(.ant-radio) {
    width: 100%;
  }
}

.card-cart {
  margin-top: 24px;
  .ant-card-body {
    padding: 0;
  }
}

.member-top {
  background-color: #ececec;
  padding: 24px !important;
  padding-block: 24px !important;
}
.member-bottom {
  background-color: #f2d6ce;
  padding: 15px 24px 7px !important;
  padding-top: 15px !important;
  padding-bottom: 7px !important;
}

.PhoneInputInput {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-color: #d9d9d9;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px !important;
}

.currency-select {
  font-family: FiraCode;
  font-weight: 500 !important;
  width: 84px !important;
  .ant-select-selection-item {
    padding-inline-end: 0 !important;
    text-align: center;
  }
  .ant-select-arrow {
    display: none;
  }
}
.currency-active {
  background-color: $primary !important;
}
.currency-select-text {
  font-family: FiraCode;
  font-weight: 500 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  .text {
    margin-right: 8px;
    // letter-spacing: 1px;
  }
  .symbol {
  }
}
// .ant-menu-item-selected {
//   background-color: $primary !important;
// }

.product-name {
  height: 54px !important;
  font-size: 14px !important;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  margin-bottom: 0;
}
.player-wrapper {
  position: relative;
  aspect-ratio: 9/16;
  max-width: 100%;
  max-height: 855px;
  div {
    max-width: 100% !important;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.title3line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  overflow: hidden;
  height: 54px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin-bottom: 0;
}
.text-discount {
  margin-top: 0;
  word-break: break-word;
  line-height: 1.5714285714285714;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-bottom: 0 !important;
}
.price-with-discount {
  margin-top: 24px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-bottom: 0;
}
.price-without-discount {
  margin-top: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgb(245, 34, 45) !important;
  margin-bottom: 0;
}
.space-total {
  border: 1px solid rgba(5, 5, 5, 0.06);
  text-align: center;
  width: 100%;
  .ant-space-item {
    padding: 15px 10px;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    &:last-child {
      border-bottom: 0;
    }
  }
}
.container-search {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  height: 65px;
  display: flex;
  .ant-input-group-wrapper {
    height: inherit;
    flex: 1;
    .ant-input-wrapper {
      height: inherit;
      input {
        height: inherit;
        border-radius: 0;
      }
      .ant-input-affix-wrapper {
        height: inherit;
        border-radius: 0;
      }
      .ant-input-group-addon {
        height: inherit;
        .ant-btn {
          height: inherit;
          border-radius: 0;
          width: 50px;
          border-left: 0;
        }
      }
    }
  }
  .btn-close {
    height: inherit;
    width: 50px;
    border-radius: 0;
  }
}
.number-clock {
  border: 1px solid rgba(0, 0, 0, 0.45);
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-no-border {
  border: 0;
  border-radius: 0;
  .ant-card-cover {
    img {
      border-radius: 0;
    }
  }
  .ant-card-body {
    padding: 24px 7px;
    .title {
      font-size: 16px;
      font-weight: normal;
    }
    .desc {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      color: rgb(142, 142, 142);
    }
  }
}
.swiper-social {
  width: 250px !important;
}
.card-social {
  border: 0;
  border-radius: 0;
  width: 100%;
  position: relative;
  .bottom {
    padding: 0 10px !important;
    overflow: hidden;
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .icon-shop {
      color: #ffffff;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.brand-container {
  cursor: pointer;
  // minHeight: 100;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}
.brand-wrap {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  background-color: $grayLight;
}

.disable {
  opacity: 0.5;
}

.ant-input-search {
  border-color: #e6e6e6 !important;
  .ant-input-affix-wrapper {
    border-color: #e6e6e6 !important;
    border-start-end-radius: 5px !important;
    border-end-end-radius: 5px !important;
    border-left: 0 !important;
  }
  .ant-input-group-addon {
    border-color: #e6e6e6 !important;
    border-right: 0 !important;
    background-color: transparent !important;
  }
  .ant-input-group-addon:last-child {
    display: none;
  }
}

.banner-img-pointer {
  cursor: pointer;
  max-width: 100%;
}
.banner-img {
  cursor: pointer;
  max-width: 100%;
}
.swiper-pagination-bullet-active {
  background-color: #000 !important;
}
.img-169 {
  position: relative;
  width: 100%;
  aspect-ratio: 2592/820;
  @include size__320 {
    aspect-ratio: 750/560;
  }
  @include size__640 {
    aspect-ratio: 750/560;
  }
  @include size__768 {
    aspect-ratio: 2592/820;
  }
  @include size__768 {
    aspect-ratio: 2592/820;
  }
  @include size__1024 {
    aspect-ratio: 2592/820;
  }
  @include size__1280 {
    aspect-ratio: 2592/820;
  }
}

// .mySwiper-banner {
//   aspect-ratio: 10/3;
//   // display: flex;
//   @include size__320 {
//     aspect-ratio: 640/478;
//   }
//   @include size__640 {
//     aspect-ratio: 640/478;
//   }
//   @include size__768 {
//     aspect-ratio: 10/3;
//   }
//   @include size__768 {
//     aspect-ratio: 10/3;
//   }
//   @include size__1024 {
//     aspect-ratio: 10/3;
//   }
//   @include size__1280 {
//     aspect-ratio: 10/3;
//   }
// }

.SwiperButtonPrev {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  .icon {
    cursor: pointer;
  }
}
.SwiperButtonNext {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  .icon {
    cursor: pointer;
  }
}

.line {
  width: 60;
  height: 60;
  margin-left: 5;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999;
}

.card-firstContent {
  border: 0;
  border-radius: 0;
  width: 100%;
  background-color: white;
  .ant-card-cover {
    img {
      border-radius: 0;
    }
  }
  .ant-card-body,
  .body {
    padding: 24px 7px;
    .title {
      color: #000;
      font-size: 16px;
      font-weight: normal;
    }
    .desc {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      color: rgb(142, 142, 142);
    }
  }
}

.card-third {
  border: 0;
  border-radius: 0;
  .ant-card-cover {
    width: 100%;
    aspect-ratio: 1200/1798;
    position: relative;
    overflow: hidden;
    img {
      border-radius: 0;
    }
  }
  .ant-card-body,
  .body {
    padding: 24px 7px;
    .title {
      color: #000;
      font-size: 16px;
      font-weight: normal;
    }
    .desc {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      color: rgb(142, 142, 142);
    }
  }
}

.title-content-sec {
  font-weight: normal !important;
  font-size: 24px !important;
}
.title-sub-content-sec {
  font-weight: normal !important;
  font-size: 16px !important;
  color: rgb(142, 142, 142) !important;
}

.card-article {
  // background-color: #f3f4f4;
  margin-bottom: 14px;
  border: 1px solid transparent;
  &:hover {
    transition: 0.3s;
    border-color: 0.2s;
    .body,
    .body-long {
      .date,
      .desc,
      .des-long {
        // color: #ffffff !important;
      }
    }
  }

  .cover {
    position: relative;
    width: 100%;
    aspect-ratio: 4/3;
    border-radius: 3px;
    overflow: hidden;
  }
  .body,
  .body-long {
    padding: 14px 7px;

    .title-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 7px;
      .title {
        padding-right: 14px;
        margin-top: 7px;
        margin-bottom: 0;
      }
      .date {
        margin-top: 0;
      }
    }
    .date {
      color: $primary;
      text-align: right;
      font-size: 12px;
      font-weight: 200;
      margin-bottom: 0;
      white-space: nowrap;
    }
    .title {
      font-size: 18px;
      font-weight: normal;
      margin-top: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .desc,
    .des-long {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 200;
      color: rgb(142, 142, 142);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .desc {
      -webkit-line-clamp: 2;
    }
    .des-long {
      -webkit-line-clamp: 3;
    }
  }
  .body-long {
    padding-left: 7px;
    padding-top: 0;
  }
}

.article-detail {
  .cover {
    margin-block: 24px;
    position: relative;
    width: 100%;
    aspect-ratio: 4/3;
    text-align: center;
  }
  .date {
    color: #787878;
    font-weight: 200;
    font-size: 14px;
    margin-block: 14px !important;
  }
}

.article-content {
  margin-top: 24px;
  .ql-align-center {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
  }
  p {
    font-weight: 200;
    line-height: 1.5;
  }
}
.article-content-readmore {
  transition: all 0.5s ease-in-out;
  max-height: 560px;
  overflow: hidden;
  &.show {
    overflow: auto;
    font-size: inherit;
    max-height: 999em;
  }
  &.hide {
    max-height: 560px;
    overflow: hidden;
    -webkit-mask-image: -webkit-gradient(
      linear,
      center bottom,
      center top,
      color-stop(0, rgba(0, 0, 0, 0)),
      color-stop(1, rgba(0, 0, 0, 1))
    );
  }
}
.readmore {
  text-align: center;
  margin-bottom: 24px;
  cursor: pointer;
}
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
}
#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#preloader:after {
  left: auto;
  right: 0;
}
#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

.ant-list-item-meta {
  align-items: center !important;
}
.ant-list-item-meta-avatar {
  display: flex;
}
.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}

.branch-radio-group {
  width: 100%;
  .radio {
    display: flex;
    width: 100%;
    padding-block: 4px;
  }
}

.pickup-container {
  .pickup-item {
    display: flex;
    .pickup-item-title {
      min-width: 120px;
      // width: 35%;
    }
    .pickup-item-detail {
      // width: 65%;
    }
  }
}

.creditcard-container,
.address-container {
  width: 100%;
  label {
    width: 100%;
    padding: 10px;
    border: 1px solid $grayLight;
    border-radius: 5px;
  }
}

.payment-container {
  width: 100%;
  .ant-radio {
    align-self: flex-start;
    margin-top: 5px;
  }
  .ant-radio-wrapper {
    width: 100%;
    padding: 10px;
    border: 1px solid $grayLight;
    border-radius: 5px;
  }
}

:where(.css-dev-only-do-not-override-1hxehff).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #000000;
  background-color: #000000;
}
.currency-badge {
  width: 94.2px;
}

.card-text-discount {
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}
.img-payment {
  display: flex;
}

@import "pages/cart.scss";
@import "pages/checkout.scss";
@import "pages/detail.scss";
@import "pages/signup.scss";
