@font-face {
  font-family: "Prompt";
  src: url("/static/fonts/Prompt/Prompt-Light.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Prompt";
  src: url("/static/fonts/Prompt/Prompt-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Prompt";
  src: url("/static/fonts/Prompt/Prompt-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
