@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul {
    list-style: initial; 
    margin: initial;
    padding: initial;
  }
}