.btn-login {
  border-radius: 4px !important;
  background-color: black;
  color: white;
  &:hover {
    background-color: $primary !important;
    color: black !important;
  }
}
.btn-login-outline {
  border-radius: 4px !important;
  background-color: white;
  color: black;
  border-color: black;
  &:hover {
    background-color: black !important;
    color: white !important;
  }
}
.input-login {
  border-radius: 4px !important;
}

.tabs-login {
  .ant-tabs-nav-wrap {
    justify-content: center;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: black !important;
        }
        &::after {
          border: 0;
        }
      }
    }
  }
}
