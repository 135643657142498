.btn-add {
  border-radius: 4px !important;
  background-color: $primary !important;
  color: black !important;
  border: 0;
  font-weight: 500;
  &:hover {
    @import "pages/signup.scss";
    background-color: black !important;
    color: $primary !important;
  }
}
.btn-buy {
  border-radius: 4px !important;
  background-color: black !important;
  color: $primary !important;
  border: 0;
  font-weight: 500;
  &:hover {
    @import "pages/signup.scss";
    background-color: black !important;
    color: $primary !important;
  }
}
.space-buy {
  width: 100%;
  .ant-space-item {
    flex: 1;
    // &:first-child {
    //   flex: 2;
    // }
  }
}

.select-size {
  .ant-radio-wrapper {
    margin-right: 0;
    .ant-radio {
      display: none;
    }
    .select-size-radio {
      margin-bottom: 15px;
      font-size: 16px;
      min-width: 70px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      padding: 10px;
      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
  .ant-radio-wrapper-checked {
    .select-size-radio {
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
    }
  }
}

.select-color {
  .ant-radio-wrapper {
    margin-right: 0;
    .ant-radio {
      display: none;
    }
    .select-color-radio {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      .color {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      .anticon {
        position: absolute;
        color: white;
        display: none;
      }
      &.disable {
        opacity: 0.3;
      }
    }
  }
  .ant-radio-wrapper-checked {
    .select-color-radio {
      border: 1px solid #000000;
      .anticon {
        display: inline-block;
      }
    }
  }
}

.thumbnail {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  outline: none;
  border: 3px solid #000000 !important;
}

.image-gallery-icon {
  color: "#ffffff";
  transition: all 0.3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #aaaaaa);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #000000;
      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }

  &:focus {
    outline: 2px solid $ig-blue;
  }
}

#product_description {
  *img {
    max-width: 100% !important;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
}
