.input-checkout {
  border-radius: 4px !important;
  .ant-select-selector {
    border-radius: 4px !important;
  }
}

.tabs-checkout {
  .ant-tabs-nav-wrap {
    justify-content: center;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: black !important;
        }
        &::after {
          border: 0;
        }
      }
    }
  }
}

.btn-payment {
  align-items: center;
  display: flex;
  height: 50px !important;
  border-radius: 4px !important;
  &.active {
    border-color: #000000;
  }
  .ant-typography {
    padding: 5px;
  }
}

.btn-delivery {
  align-items: center;
  display: flex;
  height: auto !important;
  border-radius: 4px !important;
  &.active {
    border-color: #000000 !important;
    background-color: #000000 !important;
    .ant-typography {
      color: #ffffff !important;
    }
  }
  .ant-typography {
    padding: 5px;
  }
}
